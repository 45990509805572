exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gi-about-us-js": () => import("./../../../src/pages/gi/about-us.js" /* webpackChunkName: "component---src-pages-gi-about-us-js" */),
  "component---src-pages-gi-ibd-science-current-research-in-ibd-js": () => import("./../../../src/pages/gi/ibd-science/current-research-in-ibd.js" /* webpackChunkName: "component---src-pages-gi-ibd-science-current-research-in-ibd-js" */),
  "component---src-pages-gi-ibd-science-ibd-treatment-guidelines-js": () => import("./../../../src/pages/gi/ibd-science/ibd-treatment-guidelines.js" /* webpackChunkName: "component---src-pages-gi-ibd-science-ibd-treatment-guidelines-js" */),
  "component---src-pages-gi-ibd-science-mechanisms-of-ibd-focus-on-s-1-p-js": () => import("./../../../src/pages/gi/ibd-science/mechanisms-of-ibd-focus-on-s1p.js" /* webpackChunkName: "component---src-pages-gi-ibd-science-mechanisms-of-ibd-focus-on-s-1-p-js" */),
  "component---src-pages-gi-ibd-science-video-library-ibd-perspectives-js": () => import("./../../../src/pages/gi/ibd-science/video-library-ibd-perspectives.js" /* webpackChunkName: "component---src-pages-gi-ibd-science-video-library-ibd-perspectives-js" */),
  "component---src-pages-gi-our-pipeline-congress-activities-js": () => import("./../../../src/pages/gi/our-pipeline/congress-activities.js" /* webpackChunkName: "component---src-pages-gi-our-pipeline-congress-activities-js" */),
  "component---src-pages-gi-our-pipeline-etrasimod-js": () => import("./../../../src/pages/gi/our-pipeline/etrasimod.js" /* webpackChunkName: "component---src-pages-gi-our-pipeline-etrasimod-js" */),
  "component---src-pages-gi-our-pipeline-olorinab-js": () => import("./../../../src/pages/gi/our-pipeline/olorinab.js" /* webpackChunkName: "component---src-pages-gi-our-pipeline-olorinab-js" */),
  "component---src-pages-gi-our-pipeline-publications-archive-js": () => import("./../../../src/pages/gi/our-pipeline/publications-archive.js" /* webpackChunkName: "component---src-pages-gi-our-pipeline-publications-archive-js" */),
  "component---src-pages-gi-resources-js": () => import("./../../../src/pages/gi/resources.js" /* webpackChunkName: "component---src-pages-gi-resources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

